import {
  SET_DASHBOARD_CARD_DATA,
  SET_BOOKING_CARD_DATA,
  SET_ALL_ACCOUNTS,
  SET_LIVE_CLASS_CARD_DATA,
  SET_BOOKINGS,
  SET_SALES,
  SET_EXPANSION_COUNT,
  SET_EXPANSION_SUMMARY
} from "../../actions/types/types";

const initState = {
  cardData: {},
  bookingCardData: {},
  accounts: [],
  liveClassCardData: {},
  bookings: [],
  sales: [],
  expansionCount: "",
  expansionSummary: {}
};

const Dashboard = (state = initState, action) => {
  switch (action.type) {
    case SET_DASHBOARD_CARD_DATA:
      return {
        ...state,
        cardData: action.payload.data
      };
    case SET_BOOKING_CARD_DATA:
      return {
        ...state,
        bookingCardData: action.payload.data
      };
    case SET_ALL_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload.data
      };
    case SET_LIVE_CLASS_CARD_DATA:
      return {
        ...state,
        liveClassCardData: action.payload.data
      };
    case SET_BOOKINGS:
      return {
        ...state,
        bookings: action.payload.data
      };
    case SET_SALES:
      return {
        ...state,
        sales: action.payload.data
      };
    case SET_EXPANSION_COUNT:
      return {
        ...state,
        expansionCount: action.payload.data
      };
    case SET_EXPANSION_SUMMARY:
      return {
        ...state,
        expansionSummary: action.payload.data
      };
    default:
      return { ...state };
  }
};

export default Dashboard;
