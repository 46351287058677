import { SET_REVIEW_LIST, SET_REVIEW_COUNT } from "../types/types";

export const setReviewList = value => {
  return {
    type: SET_REVIEW_LIST,
    payload: { data: value }
  };
};

export const setReviewCount = value => {
  return {
    type: SET_REVIEW_COUNT,
    payload: { data: value }
  };
};
