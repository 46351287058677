import {
    SET_ACCOUNTS_DATA,
    SET_ACCOUNT_DATA,
    SET_ACCOUNT_COUNT,
  } from '../types/types';
  
  export const setAccountsData = (value) => {
    return {
      type: SET_ACCOUNTS_DATA,
      payload: { data: value },
    };
  };
  export const setAccountData = (value) => {
    return {
      type: SET_ACCOUNT_DATA,
      payload: { data: value },
    };
  };
  
  export const setAccountCount = (value) => {
    return {
      type: SET_ACCOUNT_COUNT,
      payload: { data: value },
     
    };
  };



  