import {
  SET_ADMINS_DATA,
  SET_ADMIN_DATA,
  SET_ADMIN_COUNT,
} from '../types/types';

export const setAdminsData = (value) => {
  return {
    type: SET_ADMINS_DATA,
    payload: { data: value },
  };
};
export const setAdminData = (value) => {
  return {
    type: SET_ADMIN_DATA,
    payload: { data: value },
  };
};

export const setAdminCount = (value) => {
  return {
    type: SET_ADMIN_COUNT,
    payload: { data: value },
  };
};
