import React from "react";
import { PulseLoader } from "react-spinners";

class SpinnerComponent extends React.Component {
  state = {
    loading: true,
  };
  render() {
    return (
      <div className="sweet-loading">
        <PulseLoader
          className="PulseLoader"
          sizeUnit={"px"}
          size={15}
          color={"#7b00a3"}
          loading={this.state.loading}
        />
      </div>
    );
  }
}

export default SpinnerComponent;
