
import React from 'react';
import { connect } from 'react-redux';

const PrimaryButton = (props) =>{
    return (
      <button
          className={`btn ${props.styleClass}`}
          onClick={() => props.onClick()}
          disabled={props.disabled}
        >
          {props.value}
        </button>
    );
  };

const mapStateToProps = (state) => {
  const {login} = state.login;
  return {login};
};
export default connect(mapStateToProps, {  })(PrimaryButton);
