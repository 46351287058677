import { SET_MESSAGE_DATA } from '../actions/types/types';

const initState = {
  message: {},
};

const MessageReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_MESSAGE_DATA:
      return {
        ...state,
        message: action.payload.data,
      };
    default:
      return { ...state };
  }
};

export default MessageReducer;
