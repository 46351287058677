import { SET_LOGIN_USER, SET_LOGIN_FLAG, SET_USER_STATE } from '../types/types';

export const setLoginUser = (value) => {
  return {
    type: SET_LOGIN_USER,
    payload: { data: value },
  };
};
export const setUserState = (value) => {
  return {
    type: SET_USER_STATE,
    payload: { data: value },
  };
};

export const setLoginFlag = (value) => {
  return {
    type: SET_LOGIN_FLAG,
    payload: { data: value },
  };
};
