import { SET_REVIEW_LIST, SET_REVIEW_COUNT } from "../../actions/types/types";

const initState = {
  reviewList: [],
  reviewCount: 0
};

const ReviewReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_REVIEW_LIST:
      return {
        ...state,
        reviewList: action.payload.data
      };
    case SET_REVIEW_COUNT:
      return {
        ...state,
        reviewCount: action.payload.data
      };
    default:
      return { ...state };
  }
};

export default ReviewReducer;
