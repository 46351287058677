import {
    SET_PAYMENT_DASHBOARD_ACCOUNTS_DATA,
    SET_PAYMENT_DASHBOARD_ACCOUNT_DATA,
    SET_PAYMENT_DASHBOARD_ACCOUNT_COUNT,
  } from '../../actions/types/types';
  
  const initState = {
    accounts: [],
    account: {},
    accountCount: 0,
  };
  
  const PaymentDashboardReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_PAYMENT_DASHBOARD_ACCOUNTS_DATA:
            return {
              ...state,
              accounts: action.payload.data,
            };
      case SET_PAYMENT_DASHBOARD_ACCOUNT_DATA:
        return {
          ...state,
          account: action.payload.data,
        };
      case SET_PAYMENT_DASHBOARD_ACCOUNT_COUNT:
        return {
          ...state,
          accountCount: action.payload.data,
        };
      default:
        return { ...state };
    }
  };
  
  
  export default PaymentDashboardReducer;
  