import { combineReducers } from "redux";
import LoginReducer from "./login/loginReducer";
import sidebarReducer from "./sidebar/sidebarReducer";
import GymReducer from "./gym/gymReducer";
import SubscriptionPlanReducer from "./subscriptionPlan/subscriptionPlanReducer";
import TrainerReducer from "./trainer/trainerReducer";
import PermissionReducer from "./permission/permissionReducer";
import AdminReducer from "./admin/adminReducer";
import SelectReducer from "./selectReducer";
import MessageReducer from "./messageReducer";
import AccountReducer from "./account/accountReducer";
import SoftwareDashboardReducer from "./dashboard/softwareDashboardReducer";
import PaymentDashboardReducer from "./dashboard/paymentDashboardReducer";
import RevenueDashboardReducer from "./dashboard/revenueDashboardReducer";
import DashboardDataReducer from "./dashboard/dashboardReducer";

import { reducer as toastrReducer } from "react-redux-toastr";
import AgreementReducer from "./agreement/agreementReducer";
import MemberReducer from "./member/memberReducer";
import ReviewReducer from "./review/reviewReducer";

const rootReducer = combineReducers({
  login: LoginReducer,
  toastr: toastrReducer,
  sidebar: sidebarReducer,
  gym: GymReducer,
  plan: SubscriptionPlanReducer,
  trainer: TrainerReducer,
  permissions: PermissionReducer,
  admin: AdminReducer,
  select: SelectReducer,
  message: MessageReducer,
  account: AccountReducer,
  softwareDashboardData: SoftwareDashboardReducer,
  paymentDashboardData: PaymentDashboardReducer,
  revenueDashboardData: RevenueDashboardReducer,
  dashboardData: DashboardDataReducer,
  agreements: AgreementReducer,
  member: MemberReducer,
  review: ReviewReducer
});

export default rootReducer;
