import React, { useState, useEffect } from "react";

import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import Title from "../../../components/title";
import { FormGroup, Label, Row, Col } from "reactstrap";
import Button from "../../../components/buttons/primaryButton";
import ReactFlagsSelect from "react-flags-select";

import "react-datepicker/dist/react-datepicker.css";
import { addTrainerModal } from "../../../redux/actions/sidebar/sidebarAction";
import { useDispatch, useSelector } from "react-redux";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

const formSchema = Yup.object().shape({
  name: Yup.string().required("Trainer name is required"),
  phone: Yup.number().required("Valid phone number is required"),
  country: Yup.string()
    .typeError("Country is required")
    .required("Country is required"),
  email: Yup.string()
    .email("Please enter valid Username i.e email")
    .required("Username is required")
});

// const isPossible= ({value && isPossiblePhoneNumber(value) ? 'true' : 'false'})
// const isPossiblePhoneNumber('+12133734253') === true
function TrainerForm({
  name,
  email,
  phone,
  handleSubmit,
  subscriptionPlan,
  startDate,
  endDate,
  canLive,
  country
}) {
  const dispatch = useDispatch();
  let plan = useSelector(state => state.plan.plan);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneNumberValid, setisPhoneNumberValid] = useState(true);
  useEffect(() => {
    const isValid =
      phoneNumber && isValidPhoneNumber(phoneNumber) ? true : false;
    setisPhoneNumberValid(isValid);
  }, [phoneNumber]);

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          name,
          email,
          phone,
          canLive,
          startDate,
          endDate,
          subscriptionPlan,
          country
        }}
        validationSchema={formSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          values,
          setFieldValue
        }) => {
          return (
            <Form className="admin-details">
              <div className="trainer-info-wrapper info-wrapper border-bottom-1 blk-padding-y">
                <div className="info-title">
                  <Title title="Trainer Info" />
                </div>
                <div className="info trainer-info">
                  <Row>
                    <Col md={4}>
                      <FormGroup className="custom-inputs mb-md-0">
                        <Field
                          type="text"
                          name="name"
                          id="adminname"
                          maxLength={50}
                          placeholder="Trainer Name"
                          className={`form-control pr-5 ${
                            errors.name && touched.name && "is-invalid"
                          }`}
                        />
                        {errors.name && touched.name ? (
                          <div className="invalid-feedback">{`${errors.name}`}</div>
                        ) : null}
                        <Label for="firstname" className="">
                          Trainer Name
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup className="custom-inputs mb-md-0">
                        <Field
                          type="email"
                          name="email"
                          id="adminname"
                          maxLength={50}
                          placeholder="Username"
                          className={`form-control pr-5 ${
                            errors.email && touched.email && "is-invalid"
                          }`}
                        />
                        {errors.email && touched.email ? (
                          <div className="invalid-feedback">{`${errors.email}`}</div>
                        ) : null}
                        <Label>User Name</Label>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup className="custom-inputs mb-md-0">
                        <PhoneInput
                          international
                          defaultCountry="JO"
                          value={phoneNumber}
                          placeholder="Phone Number"
                          maxLength={20}
                          onChange={value => {
                            if (value) {
                              setPhoneNumber(value);
                              if (isValidPhoneNumber(value)) {
                                // console.log("Here", value);
                                setFieldValue("phone", value);
                              } else {
                                setFieldValue("phone", "");
                              }
                            }
                          }}
                          // onFocus= {(isPhoneNumberValid)=>{
                          //   {isPhoneNumberValid?null:<span className="">invalid</span>}
                          // }}
                          // onFocus= {() =>{
                          //   isValidNumber();
                          // }}
                          className={` ${
                            errors.phone && touched.phone && "is-invalid"
                          }`}
                        />
                        {/* {values.phone.length > 0 &&
                        isPhoneNumberValid ? null : (
                          <span className="">invalid</span>
                        )} */}

                        {errors.phone && touched.phone ? (
                          <div className="invalid-feedback">{`${errors.phone}`}</div>
                        ) : null}
                        <Label>Phone Number</Label>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup className="custom-inputs mb-md-0 mt-3">
                        <ReactFlagsSelect
                          selected={values.country}
                          countries={["JO", "SA", "AE", "EG"]}
                          searchable
                          onSelect={val => {
                            setFieldValue("country", val);
                          }}
                        />
                        {errors.country && touched.country ? (
                          <div className="invalid-feedback d-block">{`${errors.country}`}</div>
                        ) : null}
                        <Label>Country</Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="action-wrapper blk-padding-y">
                <div className="action-inner-block">
                  <div className="action-btn-block">
                    <Button
                      value="Cancel"
                      styleClass="btn-medium btn-transparent"
                      onClick={() => {
                        dispatch(addTrainerModal(false));
                      }}
                    />
                    <button
                      value="Add Trainer"
                      className="btn btn-medium"
                      type="submit"
                    >
                      Add Trainer
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
}

TrainerForm.defaultProps = {
  name: "",
  email: "",
  phone: "",
  startDate: new Date(),
  endDate: new Date(),
  activeStatus: "ACTIVE",
  canLive: true,
  subscriptionPlan: "",
  handleSubmit: values => {},
  edit: false,
  user: false,
  type: "",
  country: ""
};

export default TrainerForm;
