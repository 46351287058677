import React from "react";
import Navbar from "../components/navbar/navbar";
import Sidebar from "../components/sidebar/sidebar";
import { useDispatch, useSelector } from "react-redux";
import { setSidebarStatus } from "../redux/actions/sidebar/sidebarAction";
import Success from "../components/SuccessFul";
const AdminLayout = ({ children, props, ...rest }) => {
  const dispatch = useDispatch();
  const { message } = useSelector((state) => ({
    message: state.message.message,
  }));
  return (
    <div>
      <main className="admin-panel">
        <div className="navbar-block">
          <Navbar />
        </div>
        <div className="sidebar-layout-wrapper">
          <Sidebar />
        </div>
        <div
          className="admin-layout-body"
          onClick={() => {
            dispatch(setSidebarStatus(false));
          }}
        >
          {message.type === "success" ? (
            <Success title={message.message} />
          ) : (
            ""
          )}
          {children}
        </div>
      </main>
    </div>
  );
};

export default AdminLayout;
