import { SET_PERMISSION_DATA, SET_USER_PERMISSION_DATA } from '../../actions/types/types';

const initState = {
  permissions: [],
  userPermissions:[]
};

const PermissionReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_PERMISSION_DATA:
      return {
        ...state,
        permissions: action.payload.data,
      };
    case SET_USER_PERMISSION_DATA:
      return {
        ...state,
        userPermissions: action.payload.data,
      };
    default:
      return { ...state };
  }
};

export default PermissionReducer;
