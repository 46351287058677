import React from 'react';
import {
  Navbar,
  NavbarToggler,
  NavbarBrand,
} from 'reactstrap';
import { setSidebarStatus } from '../../redux/actions/sidebar/sidebarAction';
import { connect } from 'react-redux';
import { history } from '../../app/AppRouter';

class Header extends React.Component {
  render() {
    return (
      <div>
        <Navbar color='white' light expand='xl' className=''>
          <NavbarBrand
            onClick={() => {
              history.push('/dashboard');
            }}
          >
            <img
              src={require('../../assets/images/logo.svg')}
              className='img-fluid brand-logo'
              alt='logo'
            />
          </NavbarBrand>
          <NavbarToggler
            onClick={async () => {
              await this.props.setSidebarStatus(!this.props.sidebarStatus);
            }}
          />
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { sidebarStatus } = state.sidebar;
  return { sidebarStatus };
};
export default connect(mapStateToProps, { setSidebarStatus })(Header);

