import React, { Component } from 'react';
import { connect } from 'react-redux';
class SuccessFul extends Component {
  render() {
    return (
      <div className="success-block">
        <div className="d-flex justify-content-center align-items-center">
            <span className="sucess-full-svg">
              <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <g id="Admin-account" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="Successully-Exporting-data" transform="translate(-626.000000, -90.000000)">
                          <g id="Toast-Message" transform="translate(255.000000, 70.000000)">
                              <g id="check_circle-24px" transform="translate(371.000000, 20.000000)">
                                  <polygon id="Path" points="0 0 32 0 32 32 0 32"></polygon>
                                  <path d="M16,2.66666667 C8.64,2.66666667 2.66666667,8.64 2.66666667,16 C2.66666667,23.36 8.64,29.3333333 16,29.3333333 C23.36,29.3333333 29.3333333,23.36 29.3333333,16 C29.3333333,8.64 23.36,2.66666667 16,2.66666667 Z M12.3866667,21.72 L7.6,16.9333333 C7.08,16.4133333 7.08,15.5733333 7.6,15.0533333 C8.12,14.5333333 8.96,14.5333333 9.48,15.0533333 L13.3333333,18.8933333 L22.5066667,9.72 C23.0266667,9.2 23.8666667,9.2 24.3866667,9.72 C24.9066667,10.24 24.9066667,11.08 24.3866667,11.6 L14.2666667,21.72 C13.76,22.24 12.9066667,22.24 12.3866667,21.72 Z" id="Shape" fill="#FFFFFF" fill-rule="nonzero"></path>
                              </g>
                          </g>
                      </g>
                  </g>
              </svg>
            </span>
            <span className="sucessful-msg">
              <h3 className="mb-0 text-white f-bold ml-2">
               {this.props.title}
              </h3>
            </span>
          </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, {})(SuccessFul);