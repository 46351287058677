import {
    SET_ACCOUNTS_DATA,
    SET_ACCOUNT_DATA,
    SET_ACCOUNT_COUNT,
  } from '../../actions/types/types';
  
  const initState = {
    accounts: [],
    account: {},
    accountCount: 0,
  };
 
  const AccountReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_ACCOUNTS_DATA:
            return {
              ...state,
              accounts: action.payload.data,
            };
      case SET_ACCOUNT_DATA:
        return {
          ...state,
          account: action.payload.data,
        };
      case SET_ACCOUNT_COUNT:
        return {
          ...state,
          accountCount: action.payload.data,
        };
      default:
        return { ...state };
    }
  };

  
  export default AccountReducer;
  