import {
    SET_PRIVACY_POLICY,
    SET_TERMS
  } from '../types/types';
  
  export const setPrivacyPolicy = (value) => {
    return {
      type: SET_PRIVACY_POLICY,
      payload: { data: value },
    };
  };
  export const setTerms = (value) => {
    return {
      type: SET_TERMS,
      payload: { data: value },
    };
  };

  