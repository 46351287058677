import {
    SET_REVENUE_DASHBOARD_ACCOUNTS_DATA,
    SET_REVENUE_DASHBOARD_ACCOUNT_DATA,
    SET_REVENUE_DASHBOARD_ACCOUNT_COUNT,
  } from '../types/types';
  
  export const setRevenueDashboardAccountsData = (value) => {
    return {
      type: SET_REVENUE_DASHBOARD_ACCOUNTS_DATA,
      payload: { data: value },
    };
  };
  export const setRevenueDashboardAccountData = (value) => {
    return {
      type: SET_REVENUE_DASHBOARD_ACCOUNT_DATA,
      payload: { data: value },
    };
  };
  
  export const setRevenueDashboardAccountCount = (value) => {
    return {
      type: SET_REVENUE_DASHBOARD_ACCOUNT_COUNT,
      payload: { data: value },
     
    };
  };
  
  
  
  