import React, { Component } from 'react';
import { connect } from 'react-redux';
class Title extends Component {
  render() {
    return (
        <div className="">
            <h4 className={`mb-0 f-500 text-capitalize ${this.props.titlestyle}`}>{this.props.title} <span className={`h5 ${this.props.subtitlestyle}`}>{this.props.subtitle}</span></h4>
        </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, {})(Title);