import React, { useState, useEffect, useCallback } from "react";

import { useDropzone } from "react-dropzone";
import { toastr } from "react-redux-toastr";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import Title from "../title";
import { Input, FormGroup, Label, Row, Col } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import { addAdminModal } from "../../redux/actions/sidebar/sidebarAction";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../app/AppRouter";
import Resizer from "react-image-file-resizer";
import { api } from "../../api/api";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

const formSchema = Yup.object().shape({
  name: Yup.string().required("Admin name is required"),
  phone: Yup.number().required("Valid phone number is required"),
  email: Yup.string()
    .email("Please enter valid Username i.e email")
    .required("Username is required")
  // activeStatus: Yup.string().required("Status is required"),
});
const DropZone = ({ values, setFieldValue, errors }) => {
  const onDrop = useCallback(
    acceptedFiles => {
      let file = acceptedFiles[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = async event => {
          Resizer.imageFileResizer(
            file,
            300,
            300,
            "PNG",
            100,
            0,
            async uri => {
              let data = {
                fileName: acceptedFiles[0].name,
                source: uri,
                folder: "profile"
              };
              var url = await api(`file`, data, "post");
              if (url.data.status === 201) {
                setFieldValue("image", url.data.data);
              }
            },
            "base64",
            300,
            300
          );
        };
        reader.readAsDataURL(file);
      }
    },
    [setFieldValue]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    minSize: 0,
    maxSize: 10485760,
    accept: "image/*"
  });

  return (
    <div className="upload-profilephoto">
      <div className="file-input" {...getRootProps()}>
        <h6 className="upload-link mb-0">Upload Picture </h6>
        <input {...getInputProps()} />
      </div>
    </div>
  );
};
function AdminForm({
  name,
  email,
  phone,
  handleSubmit,
  // startDate,
  // endDate,
  permissions,
  // canLive,
  editAdmin,
  edit,
  activeStatus,
  image,
  ...props
}) {
  // console.log("Permissions", permissions);
  // console.log("Active Status", activeStatus);
  const dispatch = useDispatch();
  let permission = useSelector(state => state.permissions.permissions);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneNumberValid, setisPhoneNumberValid] = useState(true);
  useEffect(() => {
    const isValid =
      phoneNumber && isValidPhoneNumber(phoneNumber) ? true : false;
    setisPhoneNumberValid(isValid);
  }, [phoneNumber]);

  const disabledPermissions=["DASH-SFT",  "DASH-PAY", "DASH-RVS", "TRN", "USR", "TNC", "PP"];
  return (
    <React.Fragment>
      <Formik
        initialValues={{
          name,
          email,
          phone,
          permissions,
          // startDate,
          // endDate,
          image,
          activeStatus
          // canLive,
        }}
        validationSchema={formSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          values,

          setFieldValue
        }) => {
          // console.log("values", name);
          return (
            <Form className="admin-details">
              <div className="profile-image-block blk-padding-y border-bottom-1">
                <div className="profile-image-inner-block d-flex justify-content-center">
                  <div className="profile-image-wrapper">
                    <div className="profile-image">
                      <img
                        src={
                          values.image
                            ? values.image
                            : require("../../assets/images/no-image_user.jpg")
                        }
                        className="img-fluid img-blk"
                        alt=""
                      />
                    </div>
                    <div className="profile-image-links">
                      <div className="links file-input-link">
                        <DropZone
                          values={values}
                          setFieldValue={setFieldValue}
                          errors={errors}
                        />
                      </div>
                      <div className="links">
                        <h6
                          className="remove-link mb-0 text-uppercase"
                          onClick={() => {
                            //   if (values.image) {
                            //     await api(
                            //       `file`,
                            //       { url: values.image },
                            //       "delete"
                            //     );
                            setFieldValue("image", "");
                            // }
                          }}
                        >
                          Remove Picture{" "}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="admin-info-wrapper border-bottom-1 blk-padding-y">
                <div className="info-title">
                  <Title title="Admin info" />
                </div>
                <div className="admin-info">
                  <Row>
                    <Col md={4}>
                      <FormGroup className="custom-inputs mb-md-0">
                        <Field
                          type="text"
                          name="name"
                          id="adminname"
                          maxLength={50}
                          placeholder="Admin Name"
                          className={`form-control pr-5 ${
                            errors.name && touched.name && "is-invalid"
                          }`}
                        />
                        {errors.name && touched.name ? (
                          <div className="invalid-feedback">{`${errors.name}`}</div>
                        ) : null}

                        <Label for="firstname" className="">
                          Admin Name
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup className="custom-inputs mb-md-0">
                        <Field
                          disabled={edit}
                          type="email"
                          name="email"
                          id="adminname"
                          maxLength={50}
                          placeholder="Username"
                          className={`form-control pr-5 ${
                            errors.email && touched.email && "is-invalid"
                          }`}
                        />
                        {errors.email && touched.email ? (
                          <div className="invalid-feedback">{`${errors.email}`}</div>
                        ) : null}

                        <Label>User Name</Label>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup className="custom-inputs mb-md-0">
                        <PhoneInput
                          international
                          defaultCountry="JO"
                          value={phone}
                          placeholder="Phone Number"
                          maxLength={20}
                          onChange={value => {
                            if (!value) return;
                            setPhoneNumber(value);
                            if (isValidPhoneNumber(value)) {
                              // console.log("Here", value);
                              setFieldValue("phone", value);
                            } else {
                              setFieldValue("phone", "");
                            }
                          }}
                          // onFocus= {(isPhoneNumberValid)=>{
                          //   {isPhoneNumberValid?null:<span className="">invalid</span>}
                          // }}
                          // onFocus= {() =>{
                          //   isValidNumber();
                          // }}
                          className={` ${
                            errors.phone && touched.phone && "is-invalid"
                          }`}
                        />
                        {/* {values.phone.length > 0 &&
                        isPhoneNumberValid ? null : (
                          <span className="">invalid</span>
                        )} */}
                        {/* <Field
                          type='number'
                          name='phone'
                          id='adminname'
                          maxLength={50}
                          placeholder='+123'
                          className={`form-control ${
                            errors.phone && touched.phone && 'is-invalid'
                          }`}
                        /> */}
                        {errors.phone && touched.phone ? (
                          <div className="invalid-feedback">{`${errors.phone}`}</div>
                        ) : null}
                        <Label>Phone Number</Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="admin-previladge-wrapper border-bottom-1 blk-padding-y">
                <div className="info-title">
                  <Title title="Admin Privileges" />
                </div>
                <div className="priviladge-inner-block">
                  <div className="priviledge-selected">
                    <div className="priviledge-list">
                      <div className="selction-box">
                        <ul className="priviledge-checkbox-list-block">
                          {permission.map((value, index) => {
                            if (
                              ["DASH-SFT", "DASH-PAY", "DASH-RVS"].includes(
                                value.code
                              )
                            )
                              return;
                            else
                              return (
                                <li className="priviledge-checkbox-element">
                                  <FormGroup className="checkboxes-list" check>
                                    <Label check className="container-blk">
                                      <Input
                                        type="checkbox"
                                        id={value.id}
                                        checked={values.permissions.includes(
                                          value.id
                                        )}
                                        onChange={e => {
                                          if (e.target.checked) {
                                            let data = values.permissions;
                                            data.push(value.id);
                                            setFieldValue("permissions", data);
                                          } else {
                                            let data = values.permissions;
                                            let index = data.indexOf(value.id);
                                            data.splice(index, 1);
                                            setFieldValue("permissions", data);
                                          }
                                        }}
                                      />{" "}
                                      {value.name}
                                      <span className="checkmark"></span>
                                    </Label>
                                  </FormGroup>
                                </li>
                              );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {edit && (
                <div className="admin-status-wrapper border-bottom-1 blk-padding-y">
                  <div className="info-title">
                    <Title title="Status" />
                  </div>
                  <div className="status-inner-block">
                    <div className="time-select-box d-inline-block">
                      <div className="d-flex align-items-center">
                        <div className="switch-wrapper line-switch">
                          {/* {console.log("active", values)} */}
                          <Input
                            type="checkbox"
                            id="switchBox2"
                            value={values.activeStatus}
                            // value={activeStatus}
                            className="switch-input"
                            //  checked={values.activeStatus}
                            checked={values.activeStatus === "ACTIVE"}
                            onChange={e => {
                              if (e.target.checked)
                                setFieldValue("activeStatus", "ACTIVE");
                              else setFieldValue("activeStatus", "HIDDEN");
                            }}
                          />{" "}
                          {/* {values.activeStatus} */}
                          <Label
                            for="switchBox2"
                            className="switch-label"
                          ></Label>
                        </div>
                      </div>
                    </div>
                    <div className="d-inline-block">
                      {/* <h3 className="ctf-tx-fifth">Active</h3> */}
                      {values.activeStatus === "ACTIVE" ? (
                        <h3 className="ctf-tx-fifth">
                          Active
                          {/* {toastr.success('Status: Active')} */}
                        </h3>
                      ) : (
                        <h3 className="ctf-tx-fifth text-danger">
                          Hidden
                          {/* {toastr.error('Status: Inactive')} */}
                        </h3>
                      )}
                    </div>
                  </div>
                  {errors.activeStatus && touched.activeStatus ? (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >{`${errors.activeStatus}`}</div>
                  ) : null}
                </div>
              )}

              <div className="action-wrapper blk-padding-y pb-0">
                <div className="action-inner-block">
                  <div className="action-btn-block">
                    <button
                      className="btn btn-medium btn-transparent"
                      type="reset"
                      onClick={() => {
                        edit
                          ? history.push("/admininfolist")
                          : dispatch(addAdminModal(false));
                      }}
                    >
                      cancel
                    </button>
                    <button className="btn btn-medium" type="submit">
                      {edit ? "Save changes" : "Add Admin"}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
}

AdminForm.defaultProps = {
  name: "",
  email: "",
  phone: "",
  activeStatus: "ACTIVE",

  // canLive: true,
  // startDate: '',
  // endDate: '',
  permissions: [],
  handleSubmit: values => {
    // console.log("activeStatusdefault", values.activeStatus);
  },
  edit: false,
  type: "",
  image: ""
};

export default AdminForm;
