import {
  SET_DASHBOARD_CARD_DATA,
  SET_BOOKING_CARD_DATA,
  SET_ALL_ACCOUNTS,
  SET_LIVE_CLASS_CARD_DATA,
  SET_BOOKINGS,
  SET_SALES,
  SET_EXPANSION_COUNT,
  SET_EXPANSION_SUMMARY
} from "../types/types";

export const setLiveClassCardData = value => {
  return {
    type: SET_LIVE_CLASS_CARD_DATA,
    payload: { data: value }
  };
};

export const setAllAccounts = value => {
  return {
    type: SET_ALL_ACCOUNTS,
    payload: { data: value }
  };
};

export const setDashboardCardData = value => {
  return {
    type: SET_DASHBOARD_CARD_DATA,
    payload: { data: value }
  };
};

export const setBookingCardData = value => {
  return {
    type: SET_BOOKING_CARD_DATA,
    payload: { data: value }
  };
};

export const setBookings = value => {
  return {
    type: SET_BOOKINGS,
    payload: { data: value }
  };
};
export const setSales = value => {
  return {
    type: SET_SALES,
    payload: { data: value }
  };
};
export const setExpansionCount = value => {
  return {
    type: SET_EXPANSION_COUNT,
    payload: { data: value }
  };
};
export const setExpansionSummary = value => {
  return {
    type: SET_EXPANSION_SUMMARY,
    payload: { data: value }
  };
};
