import {
    SET_PAYMENT_DASHBOARD_ACCOUNTS_DATA,
    SET_PAYMENT_DASHBOARD_ACCOUNT_DATA,
    SET_PAYMENT_DASHBOARD_ACCOUNT_COUNT,
  } from '../types/types';
  
  export const setPaymentDashboardAccountsData = (value) => {
    return {
      type: SET_PAYMENT_DASHBOARD_ACCOUNTS_DATA,
      payload: { data: value },
    };
  };
  export const setPaymentDashboardAccountData = (value) => {
    return {
      type: SET_PAYMENT_DASHBOARD_ACCOUNT_DATA,
      payload: { data: value },
    };
  };
  
  export const setPaymentDashboardAccountCount = (value) => {
    return {
      type: SET_PAYMENT_DASHBOARD_ACCOUNT_COUNT,
      payload: { data: value },
     
    };
  };
  
  
  
  