import { SET_LOGIN_USER, SET_LOGIN_FLAG, SET_USER_STATE } from '../../actions/types/types';

const initState = {
  loginUser: {},
  loginFlag: false,
  loginUserData: {}, 
};

const LoginReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_LOGIN_USER:
      return {
        ...state,
        loginUser: action.payload.data,
        loginErrorFlag: false,
      };
    case SET_LOGIN_FLAG:
      return { ...state, loginFlag: action.payload.data };
    case SET_USER_STATE:
        return { ...state, loginUserData: action.payload.data };
    default:
      return { ...state };
  }
};

export default LoginReducer;
