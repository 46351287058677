import {
  SET_SOFTWARE_DASHBOARD_ACCOUNTS_DATA,
  SET_SOFTWARE_DASHBOARD_ACCOUNT_DATA,
  SET_SOFTWARE_DASHBOARD_ACCOUNT_COUNT,
} from '../types/types';

export const setSoftwareDashboardAccountsData = (value) => {
  return {
    type: SET_SOFTWARE_DASHBOARD_ACCOUNTS_DATA,
    payload: { data: value },
  };
};
export const setSoftwareDashboardAccountData = (value) => {
  return {
    type: SET_SOFTWARE_DASHBOARD_ACCOUNT_DATA,
    payload: { data: value },
  };
};

export const setSoftwareDashboardAccountCount = (value) => {
  return {
    type: SET_SOFTWARE_DASHBOARD_ACCOUNT_COUNT,
    payload: { data: value },
   
  };
};



