import * as serviceWorker from "./serviceWorker";
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./redux/storeConfig/store";
import { setLoginFlag, setLoginUser } from "./redux/actions/login/loginAction";
import { setUserPermissionData } from "./redux/actions/permission/permissionAction";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "./assets/scss/style.css";
import "./assets/css/responsive.css";
import { history } from "./app/AppRouter";
import Spinner from "./components/spinner/spinner";
import ReduxToastr from "react-redux-toastr";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import { createBrowserHistory } from "history";
const LazyApp = lazy(() => import("./app/AppRouter"));

const replaceHashPath = () => {
  const history = createBrowserHistory();
  const hash = history.location.hash;
  if (hash) {
    const path = hash.replace(/^#/, "");
    if (path) {
      history.replace(path);
    }
  }
};
replaceHashPath();

const jsx = (
  <Provider store={store}>
    <Suspense
      fallback={
        <div className="d-flex vh-100 align-items-center justify-content-center ">
          <Spinner />
        </div>
      }
    >
      <LazyApp />
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
    </Suspense>
  </Provider>
);

let hasRendered = false;
const renderApp = async () => {
  if (!hasRendered) {
    ReactDOM.render(jsx, document.getElementById("root"));
    hasRendered = true;
  }
};

serviceWorker.unregister();

const renderLogin = () => {
  store.dispatch(setLoginFlag(false));
  renderApp();

  // if (
  //   window.location.pathname === "/reset-password" ||
  //   window.location.pathname === "/reset-password"
  // ) {
  // } else {
  // }
};

try {
  if (
    window.location.pathname === "/reset-password" ||
    window.location.pathname === "/reset-password"
  ) {
    localStorage.removeItem("user");
    renderApp();
  }
  const login = localStorage.getItem("user");
  // const permissions = localStorage.getItem("permissions");
  if (login) {
    const loginObject = JSON.parse(login);
    store.dispatch(setLoginUser(loginObject));
    store.dispatch(setLoginFlag(true));
    // store.dispatch(setUserPermissionData(JSON.parse(permissions)));
    renderApp();
    if (
      window.location.pathname === "/connecttofit/superadmin/" ||
      window.location.pathname === "/connecttofit/superadmin"
    ) {
      history.push("/dashboard");
    }
  } else {
    renderLogin();
    // history.push("/login");
  }
} catch (e) {
  renderLogin();
}
