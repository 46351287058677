import {
    SET_PRIVACY_POLICY,
    SET_TERMS,
  } from '../../actions/types/types';
  
  const initState = {
    privacyPolicy: {},
    terms: {}
  };
  
  const AgreementReducer = (state = initState, action) => {
    switch (action.type) {
      case SET_PRIVACY_POLICY:
        return {
          ...state,
          privacyPolicy: action.payload.data,
        };
      case SET_TERMS:
        return {
          ...state,
          terms: action.payload.data,
        };
      default:
        return { ...state };
    }
  };
  
  export default AgreementReducer;
  