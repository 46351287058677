const initState = {
  admins: { selection: [], selectAll: false },
  gyms: { selection: [], selectAll: false },
  trainers: { selection: [], selectAll: false },
  accounts: { selection: [], selectAll: false },
  members: { selection: [], selectAll: false }
};

const SelectReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_SELECTION":
      return {
        ...state,
        [action.payload.target]: {
          ...state[action.payload.target],
          selection: action.payload.data
        }
      };
    case "SET_SELECTALL":
      return {
        ...state,
        [action.payload.target]: {
          ...state[action.payload.target],
          selectAll: action.payload.data
        }
      };
    default:
      return { ...state };
  }
};

export default SelectReducer;
