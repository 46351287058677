import { store } from "../redux/storeConfig/store";

import { history } from "../app/AppRouter";
import { setLoginFlag, setLoginUser } from "../redux/actions/login/loginAction";
// import {toastr} from 'react-redux-toastr';
const axios = require("axios");

// const mainUrl = 'http://localhost:1337/';
// const mainUrl = 'http://15.206.7.200:1320/';
// const mainUrl = 'https://api.connecttofit.zignuts.dev/';
// const mainUrl = 'https://api.stage.connecttofit.com/';
const mainUrl = process.env.REACT_APP_SERVER_URL;

export const api = async (endpoint, data, type) => {
  var res;
  var user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    var token = user.token;
  }

  switch (type) {
    case "post":
      await axios({
        data: data,
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-auth": token,
        },
        url: mainUrl + endpoint,
      })
        .then(function (response) {
          res = response;
        })
        .catch((err) => {
          if (err.response.status === 400) {
            res = err.response;
          }
          if (
            err.response.status === 401 ||
            err.response.status === 403 ||
            err.response.status === 503
          ) {
            localStorage.removeItem("user");
            store.dispatch(setLoginFlag(false));
            store.dispatch(setLoginUser({}));
            history.push("/");
          }
        });
      break;
    case "get":
      await axios({
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "x-auth": token,
        },
        url: mainUrl + endpoint,
      })
        .then(function (response) {
          res = response;
        })
        .catch((err) => {
          if (err.response.status === 400) {
            res = err.response;
          }
          if (
            err.response.status === 401 ||
            err.response.status === 403 ||
            err.response.status === 503
          ) {
            localStorage.removeItem("user");
            store.dispatch(setLoginFlag(false));
            store.dispatch(setLoginUser({}));
            history.push("/");
          }
        });
      break;
    case "patch":
      await axios({
        method: "patch",
        data: data,
        headers: {
          "Content-Type": "application/json",
          "x-auth": token,
        },
        url: mainUrl + endpoint,
      })
        .then(function (response) {
          res = response;
        })
        .catch((err) => {
          if (err.response.status === 400) {
            res = err.response;
          }
          if (
            err.response.status === 401 ||
            err.response.status === 403 ||
            err.response.status === 503
          ) {
            localStorage.removeItem("user");
            store.dispatch(setLoginFlag(false));
            store.dispatch(setLoginUser({}));
            history.push("/");
          }
        });
      break;
    case "delete":
      await axios({
        data: data,
        method: "delete",
        headers: {
          "Content-Type": "application/json",
          "x-auth": token,
        },
        url: mainUrl + endpoint,
      })
        .then(function (response) {
          res = response;
        })
        .catch((err) => {
          if (err.response.status === 400) {
            res = err.response;
          }
          if (
            err.response.status === 401 ||
            err.response.status === 403 ||
            err.response.status === 503
          ) {
            localStorage.removeItem("user");
            store.dispatch(setLoginFlag(false));
            store.dispatch(setLoginUser({}));
            history.push("/");
          }
        });
      break;
    case "postWithoutToken":
      await axios({
        method: "post",
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
        url: mainUrl + endpoint,
      })
        .then(function (response) {
          res = response;
        })
        .catch((err) => {
          res = err.response;
        });
      break;
    default:
      return true;
  }

  // var parsdata = await checkData(res);
  return res;
};

export const checkData = (data) => {
  return true;
};
