import {
  SET_ADMIN_DATA,
  SET_ADMINS_DATA,
  SET_ADMIN_COUNT,
} from '../../actions/types/types';

const initState = {
  admins: [],
  admin: {},
  adminCount: 0,
};

const AdminReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_ADMIN_DATA:
      return {
        ...state,
        admin: action.payload.data,
      };
    case SET_ADMINS_DATA:
      return {
        ...state,
        admins: action.payload.data,
      };
    case SET_ADMIN_COUNT:
      return {
        ...state,
        adminCount: action.payload.data,
      };
    default:
      return { ...state };
  }
};

export default AdminReducer;
